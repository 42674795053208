





























































































import { mapState, mapMutations, mapGetters } from 'vuex';
import moment from 'moment';
import { IRebox } from '@/store/modules/reboxes/types';
import TheFabButton from '@/components/ui/TheFabButton.vue';
import BasePopup from '@/components/ui/BasePopup.vue';
import RepositoryFactory from '@/services/RepositoryFactory';
import IBoxesRepository from '@/services/Repositories/Boxes/types';
import { IOrder } from '@/services/Repositories/OrdersRepository/types';
import PrintMixin from '@/mixins/PrintMixin';

const Factory = new RepositoryFactory();

export default PrintMixin.extend({
  name: 'Reboxes',
  data() {
    return {
      popup: false,
      reboxToDelete: '',
      files: '' as any,
      loading: false,
      // eslint-disable-next-line max-len
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal: false,
      minAllowedDate: '',
      maxAllowedDate: '',
    };
  },
  components: {
    TheFabButton,
    BasePopup,
  },
  computed: {
    ...mapState('globals', ['loader']),
    ...mapState('reboxes', ['reboxes', 'encodedLabelPdf']),
    ...mapGetters('orders', ['getOrder']),
    orderId(): string {
      return this.$route.params.orderId;
    },
    labelsWereNotPrinted(): boolean {
      const printedLables = this.reboxes.filter(({ printed }: IRebox) => printed === false);
      return printedLables;
    },
    boxesRepository(): IBoxesRepository {
      return Factory.get('boxes') as IBoxesRepository;
    },
  },
  methods: {
    ...mapMutations('globals', ['SET_PAGE']),
    ...mapMutations('reboxes', ['DELETE_REBOX', 'PRINTED_LABEL', 'CLEAR_REBOXES', 'ADD_LABEL']),
    ...mapMutations('lastEdited', ['ADD_EDITED_ORDER']),
    addAnotherRebox(): void {
      this.$router.push({ name: 'findRebox', params: { orderId: this.orderId } });
    },
    toggleLoading(): void {
      this.loading = !this.loading;
    },
    updateRebox(reboxId: string): void {
      this.$router.push({ name: 'createRebox', params: { orderId: this.orderId, reboxId } });
    },
    toPrintLabels(): void {
      this.toggleLoading();
      this.$router.replace({ name: 'orderLabels' });
    },
    showPopup(reboxId: string): void {
      this.popup = true;
      this.reboxToDelete = reboxId;
    },
    removeRebox(): void {
      this.DELETE_REBOX(this.reboxToDelete);
      this.popup = false;
    },
    calculateMinAllowedDate(): void {
      const today = new Date();
      this.minAllowedDate = moment(today).format('YYYY-MM-DD');
    },
    calculateMaxAllowedDate(): void {
      const twoWeeksAway = new Date(Date.now() + 12096e5);
      this.maxAllowedDate = moment(twoWeeksAway).format('YYYY-MM-DD');
    },
    async shipBoxes(): Promise<void> {
      const order = `/api/orders/${this.orderId}`;
      const boxes = this.reboxes.map((item: IRebox) => {
        const { template, reboxNr } = item;
        console.log(item);
        const templateFilteredZeroAmount = template.filter((temp) => temp.amount !== '0');
        const templateToApi = templateFilteredZeroAmount.map((templateItem) => {
          const {
            amount,
            inventoryItem: { id: inventoryItemId },
          } = templateItem;
          const inventoryItem = `/api/inventory_items/${inventoryItemId}`;
          return { inventoryItemAmount: parseInt(amount, 10), inventoryItem };
        });
        return { reboxNr, boxShippingInventoryItems: templateToApi };
      });
      const shippedBoxes: any[] = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < boxes.length; i++) {
        const { reboxNr, boxShippingInventoryItems } = boxes[i];
        const {
          data: { currentBoxShipping },
        } = await this.boxesRepository.shipBox(reboxNr, {
          order,
          boxShippingInventoryItems,
          shippingDate: `${this.date}T00:00:00.000Z`,
        });
        const {
          boxShipmentLabel: { file },
        } = currentBoxShipping;
        shippedBoxes.push(file);
        this.ADD_LABEL({ label: file, reboxId: reboxNr });
        if (this.reboxes[i].label === undefined) {
          alert('Labels are missing');
          return;
        }
      }

      const currentOrder: IOrder = this.getOrder(this.orderId);
      this.ADD_EDITED_ORDER(currentOrder.orderNr);
      this.printAllLabels(shippedBoxes);
      this.reboxes.forEach((rebox: IRebox) => {
        this.PRINTED_LABEL(rebox.reboxNr);
      });
      this.toPrintLabels();
    },
  },
  created() {
    const { icons, actions } = this.$headerService.getIconsAndActions();
    this.$headerService.config({
      title: 'header.orderProcess',
      icons: { icon: icons.cancel, action: actions.close },
    });
    this.SET_PAGE('allOrders');
    this.calculateMinAllowedDate();
    this.calculateMaxAllowedDate();
  },
  watch: {
    loading: {
      handler(value: boolean) {
        console.log(value);
      },
    },
  },
});
