



















import Vue from 'vue';

export default Vue.extend({
  name: 'BasePopup',
  props: {
    popupIsOpen: {
      default: () => false,
      type: Boolean,
      required: true,
    },
  },
});
